$bg-color: #272b30;
$bg-color-dark: #111213;
$text-color: #eeeeee;
$logo-blue: #2a397c;
$logo-red: #be2026;
$light-blue: #3d50af;
$light-red: #cf585c;
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,550,700,900'); /** font-family: 'Montserrat', sans-serif; **/

// Comment out the lines below if you want the development error overlay.
iframe {
    display: none;
}

#root {
    display: flex;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
}

.App {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    padding: 2vw;
}

button {
    background: none;
    border: none;
    padding: 0;
    font-size: 1.3em;
    font-family: 'Montserrat', sans-serif;
}

button p:hover {
    text-decoration: underline;
    cursor: pointer;
}

p, td, button {
    font-weight: bold;
}

body {
    /* width: max-content; */
    /* position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; */
    width: 100vw;
    display: flex;
    margin: 0;
}

p {
    margin: 0 0 2vh 0;
    font-size: 1.3em;
}

#currentTime {
    text-align: center;
}

.bold {
    font-weight: bolder;
}

.nextpanel {
    font-size: 1.3em;
    margin: 0 0 2vh 0;
    overflow: auto
    /* width: 100%; */
}

h1 {
    font-size: 7em;
    padding: 0;
    margin: auto;
}

.keys {
    color: green;
}

.bluealliance {
    color: #0066b3;
}

.redalliance {
    color: #ed1c24;
}

.match {
    font-size: 1.8vw;
    margin: 1em 0;
}

#kettering td, #kettering th {
    font-size: 1.3em;
    padding: 0.3vw;
    padding-bottom: 0 !important;
    font-weight: 550;
    margin: 0;
    text-align: center;
}

td.redalliance, td.bluealliance {
    font-weight: 750 !important;
}

td.matchName {
    padding-right: 1vw !important;
}

#ketteringWrapper {
    display: flex;
    justify-content: center;
    position: relative;
}

#centercontent {
    /* width: 54.02vw; */
    /* margin: auto; */
    /* display: inline-block; */
    /* justify-content: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    flex-grow: 1;
}

#nextpanel {
    /* display: block;
    position: relative;
    float: right;
    width: 18.19vw;
    margin: 2vh 4.55vw;
    margin: 2vh 0 0 0;
    clear: left; */
    display: flex;
    width: 20vw;
    flex-direction: column;
}

#counterDiv {
    /* display: inline-block; */
    position: relative;
    /* float: left; */
    text-align: center;
    /* margin: auto; */
    display: flex;
    justify-content: center;
    /* padding-left: 4.55vw; */
}

#matches {
    display: flex;
    position: relative;
    /* float: left; */
    /* width: 14.33% */
}

.column {
    flex: 50%;
}

#sidebar {
    /* position: relative;
    display: inline-block; */
    width: 20vw;
}

#bumper {
    font-weight: bold;
    padding: 1.5vw;
    color: white;
    width: max;
    font-size: 5em;
    margin: 0 0 2vh 0;
    text-align: center;
}

.redbg {
    background-color: #be2026;
}
.bluebg {
    background-color: #2a397c;
}

img {
    height: 10vh;
    position: fixed;
    float: right;
    display: block;
    bottom: 0;
    right: 0;
    padding: 2vw;
    /* clear: left; */
}

#copy {
    position: fixed;
    float: right;
    display: block;
    bottom: 0;
    left: 0;
    padding: 2vw;
    margin: 0;
}

p.copy {
    margin-top: 8px;
    margin-bottom: 0;
}

#sidebar p {
    font-size: 1.6em;
}

.rankings {
    margin: 0.3em 0;
    font-size: 1em !important;
}

td.rank {
    font-size: 0.75em !important;
    padding: 0 .1vw .1vw .1vw !important;
    text-align: center;
    margin: none;
}

a {
    color: black;
}
/* #maincontent {
    float: right;
    width: 76.76vw;
    padding: 0;
    margin-left: 4.55vw;
    /* clear: left !important;
} */

table {
    border-collapse: collapse;
    width: 100%;
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    .App {
        flex-direction: column-reverse;
    }

    #nextpanel, #sidebar, #centercontent {
        width: 100%;
    }

    table {
        width: 80%;
    }

    #copy {
        position: relative;
    }

    #logo {
        width: 10vw;
        height: auto;
    }

    #kettering td, #kettering th {
        font-size: 0.9em;
    }

    h1 {
        font-size: 4em;
    }

    .nextpanel {
        font-size: 1.2em;
    }

    p, button {
        font-size: 1.2em;
    }

    td.rank {
        font-size: 0.5em !important;
    }
}